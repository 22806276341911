import axios from "axios";

const API_URL = "https://api-stage.fiko.no";

export interface PageSettings {
  template: string;
  street: string;
  zip: string;
  city: string;
  email: string;
  delivery_start: string;
  delivery_frequency: string;
  product: string;
  full_price: string;
  price: string;
}

export async function getCurrentMonitor( userToken:string ) {
  try {
    const res = await axios(
      API_URL + '/monitor/campaigns?user=' + userToken
//'https://test.fiko.no/monitor.php'
    );

    const data = res.data;
    if (data.status !== "success") {
      throw new Error(data.message);
    }

    return data.data;
  } catch (e) {
    
    if (e && e.response.data.message) {
      throw new Error(e.response.data.message);
    }

    throw new Error("Error retrieving landing page");
  }
}
