import React, { useEffect, useState } from "react";
import { getCurrentMonitor } from "./livepage.api";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    makeStyles, 
    withStyles,
    DialogContent,
} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useWindowDimensions from "./useWindowDimensions"
import { chdir } from "process";

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  body: {
    backgroundColor: '#eee'
  },
  root: {
    minWidth: 275,
  },
  container: {
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 30,
  },
  svgTitle: {
    fill: 'white',
    color: 'white',
    display: 'block',
    margin: 'auto',
    textShadow: '1px 1px 3px black'
  },
  pos: {
  },
  pos2: {
  },
  tableHeader: {
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 20,
    borderBottom: 0
  },
  tableCell: {
    backgroundColor: '#29253F',
    color: '#fff',
    fontSize: 20,
    borderBottom: 0
  },
  table: {
    borderSpacing: '5px',
    borderCollapse: 'separate',
    minWidth: 700,
  },
});


interface iMonitor {
  campaign_id: Number,
  campaign_name: String,
  queue: Number,
  calls: Number,
  outside_hours: Number,
  agents: Number,
  logged: Number,
  addSales: Number | null,
  turnedSales: Number | null,
  answered_calls: Number,
  max_waittime: Number,
  answered_no: Number,
  answered_kpi: Number
}
/*
interface iAnimate {
  campaign_id: Number,
  isOk: Number  
}
*/
interface LandingProps {
  match?: any;
  location?: any;
}

export default function LandingPage(props: LandingProps) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<Error | null>(null);
  const [userToken, setUserToken] = useState<string>("");
  const [monitor, setMonitor] = useState<iMonitor[]>();
  const { height, width } = useWindowDimensions();
  const [svgHeight, setSvgHeight] = useState(190);
//  const [isZero, setIsZero] = useState<iAnimate[]>([{ campaign_id: 0, isOk: 1 }]);

  useEffect(() => {
    if (props.match.params && props.match.params.userToken) {
      setUserToken( props.match.params.userToken );
    }
  }, [props.match.params]);

  useEffect(() => {
    /*
    
      (width/2 * height)/number = factor

      x = sqrt(wh/4n)
    */
   if (monitor) {
    const numb = monitor.length;
   
    //console.log(Math.floor(Math.sqrt((width*height)/(3*numb))));
    setSvgHeight(Math.floor(Math.sqrt((width*height)/(3*numb))));
   }
  },[monitor, height, width]);

  useEffect(() => {
    const interval = setTimeout(() => {
      console.log('This will run every second!');
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const refreshCurrentMonitor = () => {
      if (userToken) {
        getCurrentMonitor(userToken).then((res) => {
          if (res.message !== undefined) {
            setErrorMessage(res.message); 
          } else {
            setMonitor(res);
          }
        });
      }
    }

    const interval = setInterval(() => {
      refreshCurrentMonitor();
    }, 5000);
    refreshCurrentMonitor();
    return () => clearInterval(interval);
  }, [userToken]);

  const onReloadFromError = () => {
//    setAuthorizationUrl(null);
/*
<Grid container spacing={1} justify="flex-start" alignItems="flex-start">
<Grid container item xs>
*/
  //  setErrorMessage(null);
  };
  const ch = (row: iMonitor, size: number) => {
    return size*(row.addSales !== null?0.8:1)
  }

  //let template = null;
  if (props.match.params && props.match.params.pageId)
  switch (props.match.params.pageId) {
    case "table":
      return (
        <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell className={classes.tableHeader}>Kampanje</StyledTableCell>
            <StyledTableCell className={classes.tableHeader} align="right">Kø</StyledTableCell>
            <StyledTableCell className={classes.tableHeader} align="right">Samtaler</StyledTableCell>
            <StyledTableCell className={classes.tableHeader} align="right">Totalt Agenter</StyledTableCell>
            <StyledTableCell className={classes.tableHeader} align="right">Klare agenter</StyledTableCell>
            <StyledTableCell className={classes.tableHeader} align="right">Utenfor åpningstid</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {monitor && monitor.map((row: any) => (
            <StyledTableRow key={row.campaign_id}>
              <StyledTableCell className={classes.tableCell} component="th" scope="row">
                {row.campaign_name}
              </StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">{row.queue}</StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">{row.calls}</StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">{row.agents}</StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">{row.logged}</StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">{row.outside_hours}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );
  case "visual":
  default:
      return (
        <div className={classes.container}>
          {errorMessage ? (
            <Dialog open={true}>
              <DialogTitle>En feil oppstod</DialogTitle>
              <DialogContent>
                <DialogContentText>{errorMessage.message}</DialogContentText>
              </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onReloadFromError}
                  >
                    OK
                  </Button>
                </DialogActions>
            </Dialog>
          ) : (
            <div>
              {monitor?.map((row: any) => (
                <span>
                <svg preserveAspectRatio="xMinYMin slice" viewBox="0 0 600 300" height={svgHeight}>
                  <text fontFamily="Arial, Helvetica, sans-serif" className={classes.svgTitle} fontSize="2em" textAnchor="right" x="50" y="40">{row.campaign_name}</text>

                  <rect x="290" y={ch(row,70)} width="200" height={ch(row,50)} fill="#fff"></rect>
                  <circle cx="490" cy={ch(row,95)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="380" y={ch(row,105)}>{row.logged} avail.</text>

                  <rect x="330" y={ch(row,125)} width="160" height={ch(row,50)} fill="#fff"></rect>
                  <circle cx="490" cy={ch(row,150)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="380" y={ch(row,160)}>{row.max_waittime} wait</text>

                  <rect x="330" y={ch(row,180)} width="160" height={ch(row,50)} fill="#fff"></rect>
                  <circle cx="490" cy={ch(row,205)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="380" y={ch(row,215)}>{row.agents} {row.agents===1?'agent':'agents'}</text>

                  <rect x="50" y={ch(row,70)} width="200" height={ch(row,50)} fill="#fff"></rect>
                  <circle cx="50" cy={ch(row,95)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="50" y={ch(row,105)}>{row.answered_calls} answ.</text>

                  <rect x="50" y={ch(row,125)} width="160" height={ch(row,50)} fill="#fff"></rect>
                  <circle cx="50" cy={ch(row,150)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="50" y={ch(row,160)}>{row.calls} calls</text>

                  <rect x="50" y={ch(row,180)} width="160" height={ch(row,50)} fill="#fff"></rect>
                  <circle cx="50" cy={ch(row,205)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="50" y={ch(row,215)}>{Number(row.calls) && Number(row.answered_calls)?Math.round((row.answered_calls/row.calls)*100):0} %</text>
                  {row.addSales !== null?(
                    <>
                    <rect x="50" y={ch(row,235)} width="160" height={ch(row,50)} fill="#fff"></rect>
                    <circle cx="50" cy={ch(row,260)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                    <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="50" y={ch(row,270)}>{"Ad: " + row.addSales} / {Number(row.addSales)?Math.round((row.addSales/row.calls)*100):0} %</text>

                    <rect x="330" y={ch(row,235)} width="160" height={ch(row,50)} fill="#fff"></rect>
                    <circle cx="490" cy={ch(row,260)} r={ch(row,25)} fill="#fff" stroke="none"></circle>
                    <text fontFamily="Arial, Helvetica, sans-serif" fontSize={ch(row,1.5) + 'em'} textAnchor="left" x="380" y={ch(row,270)}>{"Tu: " + row.turnedSales} / {Number(row.turnedSales)?Math.round((row.turnedSales/(row.turnedSales+row.answered_no))*100):0} %</text>
                    </>
                  ):null}
                    
                  <circle cx="270" cy="150" r="100" fill="#ffffff" stroke="#000" strokeWidth="3">
                    <animate attributeName="fill" values={!row.queue?"#ffffff;#ffffff;#ffffff":"#ffffff;#FFA500;#ffffff"} dur="2s" repeatCount="indefinite"  restart="always" />
                  </circle>
                  
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize="5em" textAnchor="middle" x="270" y="165">{row.queue}</text>
                  <text fontFamily="Arial, Helvetica, sans-serif" fontSize="1em" textAnchor="middle" x="270" y="185">in queue</text>
                  
                  <text className={classes.svgTitle} fontFamily="Arial, Helvetica, sans-serif" fontSize="1em" textAnchor="left" x="380" y="250">{row.outside_hours}  outside hours</text>
                </svg>
                </span>
              ))}
            </div>
    
          )}
      </div>
      );
    }
    return ( <>/table/&lt;api-key&gt; or /visual/&lt;api-key&gt;</> );
}