import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import LivePage from "./livepage.component";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/:pageId/:userToken" component={LivePage} />
          <Route path="/:userToken"   component={LivePage} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

/*component={() => {
              window.location.href = "https://www.fiko.no";
              return null;
}}*/
